import { Dialog, DialogContent, DialogTitle, useTheme } from '@mui/material';
import { CloseButton } from '../button';
import * as React from 'react';
import { useAppSelector } from '../../store';
import BreadcrumbsWithMenu from '../BreadcrumvsWithMenuProps';
import { palette } from '../../themes';

export type FullModalDialogProps = {
  title?: string;
  icon?: React.ReactNode;
  children?: React.ReactNode;
  open?: boolean;
  onClose?: () => void;
  onAction?: () => void;
};

export const FullModalDialog = ({
  title,
  open,
  icon,
  children,
  onClose,
  onAction,
}: FullModalDialogProps) => {
  const theme = useTheme();
  const levels = useAppSelector((state) => state.itemModal.navigation.levels);

  return (
    <Dialog fullScreen open={open} sx={{ margin: '1.5rem' }}>
      <DialogTitle
        sx={{
          background: theme.palette.info.dark,
          height: '3.25rem',
          padding: '0 1rem 0 1.5rem',
          justifyContent: levels?.length > 1 ? 'space-between' : 'flex-end',
          marginBottom: 0,
        }}
      >
        <BreadcrumbsWithMenu
          startIcon={icon}
          text={title}
          onClick={onAction}
          crumbs={levels}
          color={palette.purewhite}
        />
        <CloseButton color='white' onClick={onClose && (() => onClose())} />
      </DialogTitle>
      <DialogContent
        sx={{
          padding: '0!important',
          overflow: 'hidden',
          display: 'flex',
          flex: 1,
        }}
      >
        {children}
      </DialogContent>
    </Dialog>
  );
};
export default FullModalDialog;
