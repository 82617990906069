import { useEffect, useState } from 'react';
import { Item } from '../types';
import {
  useGetItemDetailsFullDetailsQuery,
  useGetItemDetailsMemberOfQuery,
  useGetItemDetailsApplicableClassificationsQuery,
  useGetCollectionItemsCountQuery,
  useGetPropLinksQuery,
} from '../services';

const useFetchPropLinks = (itemType: string | null, itemId: string | null, skipQuery: boolean) => {
  const { data: propLinksData, isLoading: isFetchingPropLinks } = useGetPropLinksQuery({
    itemType: itemType,
    itemId: itemId,
  }, {
    skip: !itemId || !itemType || skipQuery
  });

  return { propLinksData, isFetchingPropLinks };
};

export const useItemDetails = ({ id }: { id?: string }) => {
  const [itemId, setItemId] = useState<string | null>(id);
  const { data: itemDetailsWithFullDetails, isLoading: isFetchingFullDetails } =
    useGetItemDetailsFullDetailsQuery(
      {
        itemId: itemId,
      },
      { skip: !id }
    );

  const [itemType, setItemType] = useState<string | null>(null);
  
  useEffect(() => {
    setItemId(id);
  },[id]);

  useEffect(() => {
    if (itemDetailsWithFullDetails && itemDetailsWithFullDetails.TypeDefId) {
      setItemType(itemDetailsWithFullDetails.TypeDefId);
    }
  }, [itemDetailsWithFullDetails]);

  const shouldSkip = itemId !== itemDetailsWithFullDetails?.ID || itemType !== itemDetailsWithFullDetails?.TypeDefId;
  const { propLinksData, isFetchingPropLinks } = useFetchPropLinks(itemType, itemId, shouldSkip);

  const { data: itemDetailsWithMemberOf, isLoading: isFetchingMemberOf } =
    useGetItemDetailsMemberOfQuery(
      {
        itemId: itemId,
      },
      { skip: !id }
    );

  const { data: itemCollectionCount, isLoading: isFetchingItemCount } =
    useGetCollectionItemsCountQuery({
      collectionId: itemId,
    });

  const {
    data: itemDetailsWithApplicableClassifications,
    isLoading: isFetchingApplicableClassifications,
  } = useGetItemDetailsApplicableClassificationsQuery(
    {
      itemId: itemId,
    },
    { skip: !id }
  );

  const itemDetails = {
    ...itemDetailsWithFullDetails,
    ['MemberOf']: itemDetailsWithMemberOf?.['MemberOf'],
    ['ApplicableClassifications']: itemDetailsWithApplicableClassifications?.['ApplicableClassifications'],
  } as Item;

  return {
    itemDetails,
    isFetching:
      isFetchingFullDetails ||
      isFetchingMemberOf ||
      isFetchingApplicableClassifications ||
      isFetchingItemCount ||
      isFetchingPropLinks,
    itemCollectionCount,
    propertyDetails: itemDetailsWithFullDetails,
    propLinksData
  };
};
